.header-submenu {
  height: 80px;
  background-color: #F9FAFC;
  position: relative;
  z-index: 2;
  @media (max-width: 1280px) {
    height: 60px;
  }
  @media (max-width: 680px) {
    height: 64px;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    @media (max-width: 1024px) {
      justify-content: center;
    }
    @media (max-width: 680px) {
      justify-content: space-around;
    }
    @media (max-width: 480px) {
      justify-content: space-between;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    color: #161923;
    text-decoration: none;
    @media (max-width: 680px) {
      white-space: nowrap;
    }

    &--active {
      color: #0BC487;
      border-color: #0BC487;
    }

    &:not(:last-child) {
      margin-right: 50px;
      @media (max-width: 1024px) {
        margin-right: 58px;
      }
      @media (max-width: 680px) {
        margin-right: 0;
      }
    }
  }
}