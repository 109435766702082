.d-md-only {
    @media (max-width: 768px) {
        display: none !important;
    }
}
.d-sm-only {
    @media (min-width: 769px) {
        display: none !important;
    }
}
.align-center {
    text-align: center;
    font-size: 0;
}
.invisible {
    opacity: 0 !important;
    pointer-events: none;
}
.overflow-x-scroll {
    @media (max-width: 768px) {
        overflow-x: scroll;
    }
}
.scroll-y-locked {
    overflow-y: hidden;
}