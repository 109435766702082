html:lang(en) {
    .button {
        font-size: 16px;
        @media (max-width: 1280px) {
            font-size: 14px;
        }
    }
    .demo-card__item_1 {
        background-image: url(../images/en/lp__img_demo-card-1-en.png);
    }
    .demo-card__item_2 {
        background-image: url(../images/en/lp__img_demo-card-2-en.png);
    }
}