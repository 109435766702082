.block {
  color: #161923;
  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 0;
  }
  &__subtitle {
    color: #0bc487;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 45px;
  }
  &__description {
    font-size: 16px;
    line-height: 32px;
    font-family: 'Lato-Regular';
    color: #43464e;
  }
}
