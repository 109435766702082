.text {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 15px;
  line-height: 1.5;
  h1 {
    font-size: 24px;
    font-weight: 400;
    line-height: initial;
    font-family: 'Lato-Bold', sans-serif;
  }
  h2 {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Lato-Bold', sans-serif;
  }
  li {
    list-style-type: none;
  }
  ol {
    counter-reset: list1;
    padding-left: 0;
  }
  ol li:before {
    counter-increment: list1;
    content: counter(list1) '. ';
  }
  ol ol {
    counter-reset: list2;
    padding-left: 20px;
    @media (max-width: 768px) {
      padding-left: 10px;
    }
  }
  ol ol li:before {
    counter-increment: list2;
    content: counter(list1) '.' counter(list2) '. ';
  }
  ol ol ol {
    counter-reset: list3;
    padding-left: 20px;
    @media (max-width: 768px) {
      padding-left: 10px;
    }
  }
  ol ol ol li:before {
    counter-increment: list3;
    content: counter(list1) '.' counter(list2) '.' counter(list3) '. ';
  }
  table {
    border-collapse: collapse;
    td {
      border: 1px solid #1f2431;
      padding: 0 15px;
    }
    th {
      font-weight: normal;
      border: 1px solid #1f2431;
      padding: 0 15px;
    }
    tfoot td {
      border: none;
    }
  }
}
