.logo {
    display: flex;
    align-items: center;
    &__icon {
        flex-basis: 80px;
        height: 100%;
    }
    &__text {
        flex-basis: 140px;
        margin-left: 8%;
        color: #fff;
        height: 100%;
    }
}