.decor-01 {
    width: 165px;
    position: absolute;
    left: 30%;
    bottom: 0;
    stroke: #0bc487;
    stroke-width: 2px;
    @media (max-width: 1100px) {
        display: none;
    }
    &_en {
        bottom: 5%;
    }
}
.decor-02 {
    width: 365px;
    position: absolute;
    right: -92%;
    top: 3%;
    stroke: #0bc487;
    stroke-width: 2px;
    @media (max-width: 1280px) {
        width: 310px;
        right: -75%;
    }
    @media (max-width: 1100px) {
        display: none;
    }
}
.decor-03 {
    width: 52%;
    position: absolute;
    right: 0;
    top: -23%;
    z-index: 0;
    height: 60%;
    @media (max-width: 1720px) {
        width: 75%;
        top: -21%;
    }
    @media (max-width: 1440px) {
        width: 75%;
        top: -20%;
    }
    @media (max-width: 1280px) {
        width: 85%;
        top: -19%;
    }
    @media (max-width: 1100px) {
        display: none;
    }
}
.decor-04 {
    width: 75%;
    position: absolute;
    right: 0;
    top: -40%;
    z-index: 0;
    height: 60%;
    @media (max-width: 1720px) {
        width: 80%;
        top: -33%;
    }
    @media (max-width: 1440px) {
        width: 80%;
    }
    @media (max-width: 1280px) {
        width: 85%;
        top: -32%;
    }
    @media (max-width: 1100px) {
        display: none;
    }
}
.decor-05 {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    height: 60%;
    @media (max-width: 1100px) {
        display: none;
    }
}
.decor-06 {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 15%;
    z-index: 1;
    height: 60%;
    @media (max-width: 1100px) {
        display: none;
    }
}
