.main-info {
  background-color: #F9F9FA;
  color: #161923;

  &_bg {
    &_inner {
      background-color: #fff;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - 160px);
    @media (max-width: 1280px) {
      height: calc(100vh - 120px);
    }
    @media (max-width: 1024px) {
      height: auto;
      padding-bottom: 110px !important;
      padding-top: 55px !important;
    }
    @media (max-width: 768px) {
      height: auto;
      padding-bottom: 55px !important;
      padding-top: 55px !important;
    }
    @media (max-width: 680px) {
      flex-direction: column;
      padding: 0 !important;
    }
    &_no-subheader {
      height: calc(100vh - 80px);
      @media (max-width: 1280px) {
        height: calc(100vh - 60px);
      }
      @media (max-width: 1024px) {
        height: auto;
        padding-bottom: 55px !important;
      }
      @media (max-width: 680px) {
        padding-bottom: 0 !important;
      }
    }
  }

  &__content {
    max-width: 50%;
    @media (max-width: 680px) {
      max-width: 100%;
      padding: 74px 34px 79px;
      background: #fff;
    }
    @media (max-width: 320px) {
      padding: 35px 34px 55px;
    }
    &_wide {
      max-width: 940px;
      @media (max-width: 680px) {
        padding-bottom: 55px !important;
      }
      .main-info__text {
        max-width: 100%;
      }
    }
  }

  &__text {
    max-width: 85%;
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 22px;
    letter-spacing: 0.4px;
    line-height: 31px;
    opacity: 0.7;
    @media (max-width: 1280px) {
      font-size: 18px;
    }
    @media (max-width: 1024px) {
      max-width: 100%;
      font-size: 18px;
      letter-spacing: 0.3px;
      line-height: 28px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (max-width: 680px) {
      margin-top: 33px;
    }
    @media (max-width: 320px) {
      margin-top: 25px;
      font-size: 16px;
    }
  }

  &__btn {
    margin-top: 80px;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    @media (max-width: 1280px) {
      margin-top: 40px;
    }
    @media (max-width: 1024px) {
      margin-top: 52px;
    }
    @media (max-width: 680px) {
      margin-top: 45px;
    }
    @media (max-width: 320px) {
      margin-top: 25px;
    }
    &_download {
      display: block;
    }
    .button {
      max-width: 224px;
      min-width: 224px;
      height: 50px;
      @media (max-width: 1280px) {
        height: 40px;
      }
      @media (max-width: 1024px) {
        padding: 1px 19px 0;
      }
    }
  }

  &__btn-notice {
    margin-top: 8px;
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 31px;
    text-align: center;
    opacity: 0.6;
    @media (max-width: 1024px) {
      font-size: 12px;
    }
    &_big {
      font-size: 15px;
      line-height: 22px;
      margin-top: 55px;
      text-align: left;
      max-width: 55%;
      @media (max-width: 768px) {
        max-width: 90%;
      }
    }
  }

  &__phone {
    height: 65vh;
    @media (max-width: 1280px) {
      margin-right: 7%;
    }
    @media (max-width: 1024px) {
      margin-right: 0;
      height: 35vh;
    }
    @media (max-width: 680px) {
      display: none;
    }
    img {
      height: 100%;
      filter: drop-shadow(0px 15px 15px rgba(0,0,0,0.4));
      position: relative;
      z-index: 1;
    }
  }
  &__download-link {
    color: #0F1829;
    font-size: 14px;
    line-height: 1;
    text-decoration: none;
    margin-left: 50px;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    @media (max-width: 768px) {
      display: flex;
      margin-left: 0;
      margin-top: 55px;
    }
  }
  &__download-icon {
    margin-right: 15px;
  }
}