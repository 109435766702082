.graph {
  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 25px;
  }
  &__item {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  &__grid {
    width: 100%;
    height: auto;
  }
  &__mark {
    width: 1px;
    height: 100%;
    background-color: #0bc487;
    position: absolute;
    transition: left 0.5s;
    left: 7%;
    top: -18%;
    &:after {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #0bc487;
      position: absolute;
      bottom: 0;
      left: -2px;
    }
  }
}
