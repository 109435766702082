.lp {
  &__row {
    position: relative;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &_mb {
      margin-bottom: 120px;
      @media (max-width: 1280px) {
        margin-bottom: 60px;
      }
      @media (max-width: 680px) {
        margin-bottom: 30px;
      }
    }
    &_demo-card {
      justify-content: flex-start;
      align-items: center;
      z-index: 3;
      @media (max-width: 680px) {
        padding: 0 30px;
      }
    }
  }
  &__name {
    position: absolute;
    height: 100%;
    left: -85px;
    writing-mode: tb-rl;
    transform: rotate(180deg);
    text-transform: uppercase;
    color: #0bc487;
    font-size: 12px;
    letter-spacing: 0.64px;
    line-height: 14px;
    @media (max-width: 1440px) {
      left: -70px;
    }
    @media (max-width: 1366px) {
      left: -55px;
    }
    @media (max-width: 1280px) {
      display: none;
    }
    &_num_1 {
      text-align: right;
    }
    &_num_2 {
      text-align: left;
    }
    &_num_3 {
      top: 8px;
      text-align: right;
    }
    &_num_4 {
      top: 8px;
      text-align: right;
    }
    &_num_5 {
      top: 8px;
      text-align: right;
    }
  }
  &__img {
    max-width: 750px;
    @media (max-width: 1280px) {
      max-width: 710px;
    }
    @media (max-width: 1024px) {
      max-width: 465px;
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }
    &-item {
      width: 100%;
      @media (max-width: 768px) {
        margin-bottom: 45px;
      }
      &_create-card {
        filter: drop-shadow(-15px 0px 15px rgba(0, 0, 0, 0.2));
        @media (max-width: 1280px) {
          max-width: 75%;
          display: block;
          margin: 0 auto;
        }
        @media (max-width: 680px) {
          max-width: 100%;
          filter: drop-shadow(-5px 0px 5px rgba(0, 0, 0, 0.2));
        }
      }
    }
    &_smart-links {
      max-width: 650px;
      @media (max-width: 1024px) {
        max-width: 400px;
      }
      @media (max-width: 940px) {
        position: absolute;
        max-width: 80%;
        right: -50%;
        top: 21%;
        z-index: 0;
      }
      @media (max-width: 768px) {
        max-width: 65%;
        right: -35%;
      }
      @media (max-width: 680px) {
        position: static;
        max-width: 100%;
        right: unset;
      }
      img {
        width: 100%;
      }
    }
    &_demo-card {
      position: relative;
      width: 18vw;
      filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.4));
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  &__video {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 1.5%;
    border-radius: 20px 20px 0 0;
    @media (max-width: 1440px) {
      border-radius: 18px 18px 0 0;
    }
    @media (max-width: 1024px) {
      border-radius: 14px 14px 0 0;
    }
    @media (max-width: 768px) {
      border-radius: 12px 12px 0 0;
    }
    @media (max-width: 680px) {
      border-radius: 7px 7px 0 0;
    }
  }
  &__content {
    position: relative;
    max-width: 375px;
    @media (max-width: 1440px) {
      max-width: 375px;
    }
    @media (max-width: 1024px) {
      max-width: 310px;
      flex-shrink: 0;
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }
    &:before {
      position: absolute;
      top: -65px;
      color: #0bc487;
      @media (max-width: 768px) {
        top: -33px;
      }
    }
    &_num_1:before {
      content: '01';
      top: -33px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    &_num_2:before {
      content: '02';
    }
    &_num_3:before {
      content: '03';
    }
    &_num_4 {
      max-width: 375px;
      @media (max-width: 768px) {
        max-width: 100%;
      }
      &:before {
        content: '04';
      }
    }
    &_demo-card {
      margin-right: 16%;
      width: 21%;
      @media (max-width: 1280px) {
        margin-right: 12%;
        width: 26%;
      }
      @media (max-width: 768px) {
        margin-right: 0;
        width: 100%;
      }
    }
  }
  &__screen-num {
    position: absolute;
    left: 0;
    top: -65px;
    color: #0bc487;
    @media (max-width: 1280px) {
      left: 30px;
    }
    @media (max-width: 1024px) {
      left: 100px;
    }
    @media (max-width: 768px) {
      top: -33px;
    }
    @media (max-width: 680px) {
      left: 30px;
    }
    &_custom {
      &_01 {
        left: 0;
      }
    }
  }
  &__title {
    color: #161923;
    font-size: 28px;
    letter-spacing: 0.32px;
    line-height: 42px;
    margin-top: 0;
    margin-bottom: 55px;
    @media (max-width: 1280px) {
      margin-bottom: 35px;
    }
    @media (max-width: 768px) {
      max-width: 80%;
    }
    @media (max-width: 680px) {
      max-width: 100%;
      font-size: 24px;
      letter-spacing: 0.27px;
      line-height: 38px;
    }
    &_contact {
      font-size: 48px;
      @media (max-width: 680px) {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 15px;
      }
    }
    &_smart-links {
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
    &_review {
      @media (max-width: 1024px) {
        padding: 0 100px;
      }
      @media (max-width: 680px) {
        padding: 0 30px;
      }
    }
    &_create-card {
      text-align: center;
      width: 370px;
      margin: 0 auto 35px;
      @media (max-width: 680px) {
        width: 100%;
      }
    }
  }
  &__text {
    font-family: 'Lato-Regular';
    color: #43464e;
    font-size: 16px;
    line-height: 32px;
    @media (max-width: 768px) {
      margin-bottom: 45px;
    }
    &_smart-links {
      @media (max-width: 768px) {
        max-width: 85%;
        margin-bottom: 100px;
        position: relative;
        z-index: 1;
      }
      @media (max-width: 680px) {
        max-width: 100%;
        margin-bottom: 50px;
      }
    }
    &_create-card {
      text-align: center;
      margin: 0 auto 35px;
      max-width: 470px;
      @media (max-width: 680px) {
        width: 100%;
      }
    }
  }
  &__button {
    max-width: 224px;
    min-width: 224px;
    height: 50px;
    margin-bottom: 55px;
    @media (max-width: 1280px) {
      height: 45px;
      margin-bottom: 20px;
    }
    &_create-card {
      margin-bottom: 45px;
    }
    &_demo-card {
      max-width: 65%;
      width: 65% !important;
      min-width: unset;
      height: 7%;
      margin-bottom: 55px;
      @media (max-width: 1560px) {
        width: 80% !important;
        max-width: unset;
      }
      @media (max-width: 1280px) {
        font-size: 10px !important;
      }
      @media (max-width: 985px) {
        font-size: 9px !important;
        white-space: nowrap;
      }
    }
    &_demo-card-submit {
      width: 100%;
      max-width: 100%;
      min-width: unset;
      margin-bottom: 15px;
      @media (max-width: 1620px) {
        font-size: 12px !important;
        white-space: nowrap;
      }
      @media (max-width: 1280px) {
        font-size: 10px !important;
      }
      @media (max-width: 1024px) {
        height: 35px;
      }
    }
  }
  &__cells {
    @media (max-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  &__cell {
    flex-basis: 33%;
    @media (max-width: 768px) {
      flex-basis: 45%;
    }
    @media (max-width: 680px) {
      flex-basis: 100%;
    }
    &:not(:last-child) {
      margin-right: 80px;
      @media (max-width: 768px) {
        margin-right: 0;
      }
    }
  }
  &__icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 55px;
    margin-bottom: 40px;
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }
}
