.section {
    background-color: #F9F9FA;
    padding: 110px 0;
    @media (max-width: 768px) {
        padding: 90px 0;
    }
    @media (max-width: 680px) {
        background-color: #fff;
        padding: 30px 0;
    }
    &_smart-links {
        padding-bottom: 50px;
        @media (max-width: 940px) {
            overflow: hidden;
        }
        @media (max-width: 680px) {
            background-color: #F7F8FA;
        }
    }
    &_bg_gray2 {
        background-color: #EBEFF1;
    }
    &_create-card {
        padding-bottom: 0;
        padding-top: 55px;
        overflow: hidden;
        @media (max-width: 1440px) {
            padding-top: 0;
        }
    }
    &_contact {
        display: flex;
        align-items: center;
        @media (max-width: 680px) {
            padding: 50px 0;
        }
    }
    &_features {
        @media (max-width: 680px) {
            background-color: #F7F8FA;
            padding: 100px 0;
        }
        .inner {
            @media (max-width: 1024px) {
                padding: 0;
            }
        }
    }
    &_review {
        @media (max-width: 680px) {
            padding: 100px 0;
        }
        .inner {
            @media (max-width: 1024px) {
                padding: 0;
            }
        }
    }
    &_demo-card {
        position: relative;
        .inner {
            @media (max-width: 680px) {
                padding: 0;
            }
        }
    }
}
