.slider {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
  }
  &__val {
    font-size: 30px;
    line-height: 1;
    @media (max-width: 680px) {
      font-size: 24px;
    }
  }
}
.noUi-target {
  box-shadow: none;
  border: none;
  background: #e1e6ea;
}
.noUi-horizontal {
  height: 5px;
}
.noUi-connect {
  background: #0bc487;
}
.noUi-handle:after,
.noUi-handle:before {
  display: none;
}
.noUi-handle {
  width: 22px !important;
  height: 22px !important;
  top: -9.5px !important;
  border-radius: 50%;
  box-shadow: none;
  border: none;
  background: #0bc487;
  outline: none;
  cursor: pointer;
  @media (max-width: 1024px) {
    width: 20px !important;
    height: 20px !important;
    top: -7.5px !important;
  }
}
