@font-face {
  font-display: swap;
  font-family: 'Lato-Bold';
  src: url('../fonts/Lato-Bold.eot');
  src: url('../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Lato-Bold.woff2') format('woff2'),
    url('../fonts/Lato-Bold.woff') format('woff'), url('../fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-display: swap;
  font-family: 'Lato-Bold';
  src: url('../fonts/Lato-BoldItalic.eot');
  src: url('../fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato-BoldItalic.woff2') format('woff2'), url('../fonts/Lato-BoldItalic.woff') format('woff'),
    url('../fonts/Lato-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-display: swap;
  font-family: 'Lato-Semibold';
  src: url('../fonts/Lato-Semibold.eot');
  src: url('../fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato-Semibold.woff2') format('woff2'), url('../fonts/Lato-Semibold.woff') format('woff'),
    url('../fonts/Lato-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-display: swap;
  font-family: 'Lato-Semibold';
  src: url('../fonts/Lato-SemiboldItalic.eot');
  src: url('../fonts/Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato-SemiboldItalic.woff2') format('woff2'), url('../fonts/Lato-SemiboldItalic.woff') format('woff'),
    url('../fonts/Lato-SemiboldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-display: swap;
  font-family: 'Lato-Regular';
  src: url('../fonts/Lato-Regular.eot');
  src: url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato-Regular.woff2') format('woff2'), url('../fonts/Lato-Regular.woff') format('woff'),
    url('../fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
