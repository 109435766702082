.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start ;
  padding: 0 15px;
  &__input {
    display: none;
    &.-invalid + .checkbox__input-styled {
      border: 1px solid #ed5f4c;
    }
    &.-invalid:checked + .checkbox__input-styled {
      border: 1px solid #d0d7db;
    }
    &:checked + .checkbox__input-styled:after {
      opacity: 1;
    }
  }
  &__input-styled {
    flex-shrink: 0;
    position: relative;
    border: 1px solid #d0d7db;
    border-radius: 4px;
    width: 17px;
    height: 17px;
    margin-right: 10px;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 1px;
      width: 4px;
      height: 8px;
      border-bottom: 2px solid #0bc487;
      border-right: 2px solid #0bc487;
      transform: rotate(45deg);
      transition: all 0.3s;
      opacity: 0;
    }
  }
  &__description {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 12px;
    text-align: left;
  }
}
