.footer {
  color: #fff;
  display: flex;
  height: 620px;
  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
  }
  &__left {
    flex-basis: 45%;
    background-color: #1F2431;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px) {
      flex-basis: 100%;
    }
    &-inner {
      flex-basis: 575px;
      padding: 85px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @media (max-width: 1279px) and (min-width: 1025px) {
        padding-left: 30px;
        flex-basis: 520px;
      }
      @media (max-width: 768px) {
        flex-basis: 100%;
        padding: 85px 100px;
      }
      @media (max-width: 680px) {
        padding: 30px;
      }
    }
    &-top {
      display: flex;
      margin-bottom: 50px;
      align-items: center;
      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
      @media (max-width: 680px) {
        justify-content: space-between;
      }
      &--en {
        margin-bottom: 0;
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        justify-content: flex-start;
      }
      @media (max-width: 680px) {
        justify-content: space-between;
      }
      > div {
        @media (max-width: 768px) {
          margin-right: 125px;
        }
        @media (max-width: 680px) {
          margin-right: 0;
        }
      }
      .footer__subtitle {
        margin-bottom: 5px;
      }
    }
  }
  &__right {
    flex-basis: 55%;
    background-color: #161923;
    display: flex;
    justify-content: flex-start;
    @media (max-width: 768px) {
      flex-basis: 100%;
    }
    &-inner {
      flex-basis: 760px;
      padding: 85px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-basis: 100%;
        padding: 85px 100px;
      }
      @media (max-width: 680px) {
        padding: 30px;
      }
    }
    &-bottom {
      height: 212px;
    }
    &-top {
      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  &__logo {
    margin-right: 105px;
    width: 145px;
    height: 74px;
    @media (max-width: 680px) {
      margin-right: 0;
    }
  }

  &__soc {
    position: relative;
    top: -3px;
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 22px;
    line-height: 36px;
  }

  &__info {
    max-width: 725px;
    margin: 0;
    padding-top: 20px;
    font-size: 34px;
    letter-spacing: 1.7px;
    line-height: 41px;
    @media (max-width: 1279px) and (min-width: 1025px) {
      font-size: 28px;
    }
    @media (max-width: 1024px) {
      max-width: 625px;
    }
    @media (max-width: 680px) {
      max-width: 100%;
      padding-top: 0;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 1.1px;
    }
  }
}

.footer-menu {
  display: flex;
  flex-direction: column;

  &__item {
    font-size: 18px;
    line-height: 36px;
    color: #fff;
    text-decoration: none;
    transition: opacity .4s;

    &:hover {
      opacity: 0.8;
    }
  }
}

.subscribe-form {
  position: relative;

  .button-subscribe {
    width: 160px;
    height: 41px;
    margin-top: 50px;
    padding: 0 19px;
    display: block;
    border-radius: 40px;
    border: 2px solid #FFF;
    background: transparent;
    outline: none;
    cursor: pointer;
    font-family: 'Lato-Semibold', sans-serif;
    font-size: 14px;
    color: #fff;
  }

  input {
    max-width: 400px;
    padding-bottom: 5px;
    border-bottom: 2px solid #fff;
    font-size: 18px;
    line-height: 36px;
    color: #fff;
  }
  &__success {
    position: absolute;
    top: 45%;
    opacity: 0;
    color: #0BC487;
    transition: all .3s ease-in-out;
    &_active {
      opacity: 1;
    }
  }
}