.calc {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 135px;
  flex-wrap: wrap;
  &__ctrl {
    padding: 80px;
    flex-basis: 50%;
    background-color: #f4f7f9;
    border-radius: 15px;
    @media (max-width: 1280px) {
      padding: 40px;
    }
    @media (max-width: 980px) {
      flex-basis: 100%;
      order: 2;
    }
    @media (max-width: 680px) {
      padding: 20px;
    }
  }
  &__info {
    padding: 0 80px;
    flex-basis: 50%;
    @media (max-width: 1280px) {
      padding: 0 40px;
    }
    @media (max-width: 980px) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-basis: 100%;
      order: 1;
      padding: 0;
    }
    &-block {
      margin-bottom: 85px;
      @media (max-width: 980px) {
        flex-basis: 45%;
        margin-bottom: 40px;
      }
      @media (max-width: 680px) {
        flex-basis: 100%;
      }
      & .block__subtitle {
        @media (max-width: 680px) {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
    }
  }
  &__title {
    color: #161923;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 50px;
    margin-top: 0;
    @media (max-width: 680px) {
      font-size: 28px;
    }
    @media (max-width: 375px) {
      font-size: 26px;
    }
    @media (max-width: 320px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  &__subtitle {
    color: #9b9ea3;
    font-size: 16px;
    margin-bottom: 15px;
  }
  &__total {
    display: flex;
    justify-content: flex-end;
    font-size: 60px;
    line-height: 1;
    @media (max-width: 1024px) {
      font-size: 45px;
    }
    @media (max-width: 375px) {
      font-size: 36px;
    }
    @media (max-width: 320px) {
      font-size: 28px;
    }
    &-left {
      margin-right: 15px;
    }
    &-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-currency {
      font-size: 20px;
      color: #9b9ea3;
      text-align: center;
      @media (max-width: 375px) {
        font-size: 16px;
      }
    }
    &-period {
      font-size: 16px;
    }
  }
  &__dropdown {
    margin-bottom: 35px;
  }
  &__slider {
    margin-bottom: 40px;
    width: 96%;
  }
  &__graph {
    margin-bottom: 0;
    padding-top: 0;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: all 0.3s;
    &_active {
      opacity: 1;
      height: auto;
      margin-bottom: 40px;
      padding-top: 40px;
      @media (max-width: 980px) {
        margin-bottom: 15px;
        padding-top: 15px;
      }
    }
  }
}
