@import 'tiny-slider/dist/tiny-slider.css';
@import 'nouislider/distribute/nouislider.min.css';
@import './fonts.css';
@import './utils.css';
@import './logo.css';
@import './header-main.css';
@import './header-submenu.css';
@import './main-info.css';
@import './section.css';
@import './landing-screen-content.css';
@import './footer.css';
@import './decor.css';
@import './animation.css';
@import './demo-card.css';
@import './popup.css';
@import './calc.css';
@import './block.css';
@import './graph.css';
@import './dropdown.css';
@import './slider.css';
@import './checkbox.css';
@import './text.css';
@import './button.css';
@import './i18n.css';

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  color: #1f2431;
  font-family: 'Lato-Semibold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin: 0;
  margin-bottom: 20px;
  font-family: 'Lato-Bold', sans-serif;
  font-size: 35px;
  line-height: 41px;
  letter-spacing: 1px;
  font-weight: normal;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
}

h3 {
  margin: 0;
  margin-bottom: 20px;
  font-family: 'Lato-Bold', sans-serif;
  font-weight: normal;
  font-size: 24px;
}

address {
  font-style: normal;
}

input {
  width: 100%;
  margin-bottom: 1px;
  background: transparent;
  font-family: 'Lato-Semibold', sans-serif;
  border: none;
  border-bottom: 1px solid #f5f7f9;
  outline: none;
  font-size: 16px;
  line-height: 26px;
  transition: border-color 300ms ease;

  &.-invalid {
    margin-bottom: 0;
    border-bottom: 2px solid #ed5f4c;
  }
  &.contact__input.-invalid {
    border: 2px solid #ed5f4c;
  }

  &::placeholder {
    color: #b4bac6;
  }
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.inner {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  @media (max-width: 1280px) and (min-width: 1025px) {
    padding: 0 30px;
  }
  @media (max-width: 1024px) {
    padding: 0 100px;
    max-width: 100%;
  }
  @media (max-width: 680px) {
    padding: 0 30px;
  }
}

.link {
  text-decoration: none;
  transition: opacity .8s;

  &--green {
    color: #0BC487;
  }

  &:hover {
    opacity: 0.8;
  }
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  z-index: 4;
  opacity: 1;
  transition: opacity .3s;
  @media (max-width: 1280px) {
    font-size: 13px;
  }
}

.content {
  flex: 1;
}

.social {
  display: flex;

  &__item {

    &:not(:last-child) {
      margin-right: 14px;
    }
  }
}

.social-icon {
  width: 36px;
  height: 36px;
  background-color: #B4BAC6;
  border-radius: 50%;
  text-align: center;
  transition: all .3s ease;

  &--fb {
    .social-icon__img {
      width: 30%;
    }
  }
  &--tw {
    .social-icon__img {
      width: 60%;
    }
  }
  &:not(:last-child) {
    margin-right: 15px;
  }
  &:hover {
    background-color: #fff;
  }
  &__img {
    height: inherit;
    fill: #161923;
  }
}

.highlight {
  color: #0BC487;
}

.p-relative {
  position: relative;
}

.list {
  list-style-type: none;
  li {
    position: relative;
    &:before{
      position: absolute;
    }
  }
  &_dashed li:before {
    content: '\2014';
    left: -20px;
    top: 0;
  }
  &__nomark:before {
    display: none;
  }
  
}

.contact {
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  &__left {
    flex-basis: 350px;
    margin-right: 210px;
    @media (max-width: 1440px) {
      margin-right: 150px;
    }
    @media (max-width: 1366px) {
      margin-right: 100px;
    }
    @media (max-width: 768px) {
      flex-basis: auto;
      margin-bottom: 30px;
      margin-right: 0;
    }
    @media (max-width: 680px) {
      margin-bottom: 0;
    }
  }
  &__right {
    flex-basis: 560px;
    @media (max-width: 768px) {
      flex-basis: auto;
    }
    &.-success {
      background: #EBEFF1 no-repeat 8% 10% url(../icons/v.svg);
      .success {
        opacity: 1;
      }
      .contact__form {
        opacity: 0;
      }
    }
  }
  &__form {
    opacity: 1;
    transition: opacity .5s;
  }
  &__input {
    width: 265px;
    height: 44px;
    margin-bottom: 40px;
    @media (max-width: 680px) {
      width: 100%;
      margin-bottom: 35px;
    }
  }
  &__textarea {
    margin-bottom: 40px;
    @media (max-width: 680px) {
      margin-bottom: 35px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    align-items: flex-start;
    @media (max-width: 680px) {
      line-height: 1.6;
    }
    &-name {
      font-weight: bold;
    }
    &-value {
      color: #43464E;
      text-decoration: none;
      line-height: 1.2;
      @media (max-width: 680px) {
        line-height: 1.3;
      }
      &_link {
        border-bottom: 1px dotted #43464E;
      }
    }
  }
}

.form {
  &__row {
    display: flex;
    justify-content: space-between;
    @media (max-width: 680px) {
      flex-direction: column;
    }
    &_align {
      &_right {
        justify-content: flex-end;
        @media (max-width: 680px) {
          align-items: flex-end;
        }
      }
    }
  }
  &__input {
    background-color: #F6F6F7;
    border: 1px solid #DCE1E6;
    border-radius: 20px;
    color: #86878D;
    padding: 15px;
    &-wrapper {
      position: relative;
    }
    &-placeholder {
      position: absolute;
      color: #86878D;
      left: 15px;
      top: 13px;
      transition: all .3s;
      &_filled {
        top: -20px;
        font-size: 14px;
      }
    }
    &_textarea {
      width: 100%;
      font-family: 'Lato-Semibold', sans-serif;
      font-size: 16px;
      resize: vertical;
    }
    &:focus {
      outline: none;
      border: 1px solid #0BC487 !important;
    }
  }
  &__button {
    width: 160px;
    height: 40px;
  }
  &__description {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    @media (max-width: 1620px) {
      max-width: 100%;
    }
    @media (max-width: 1280px) {
      font-size: 12px;
    }
  }
  &__success {
    &.active {
      opacity: 1;
      height: auto;
    }
  }
}
.submit-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  @media (max-width: 980px) {
    flex-direction: column;
  }
  &__text {
    flex-basis: 50%;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 12px;
    @media (max-width: 980px) {
      flex-basis: 100%;
      text-align: center;
      order: 2;
    }
    @media (max-width: 320px) {
      font-size: 14px;
    }
  }
  &__button {
    flex-basis: 50%;
    @media (max-width: 980px) {
      flex-basis: auto;
      order: 1;
      margin-bottom: 15px;
    }
  }
}
.form-affiliate {
  max-width: 330px;
  & .popup__row {
    @media (max-width: 980px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }
  & .popup__input-wrapper {
    @media (max-width: 980px) {
      margin-bottom: 35px;
    }
    @media (max-width: 320px) {
      margin-bottom: 20px;
    }
  }
  & .checkbox {
    @media (max-width: 980px) {
      margin-bottom: 35px;
    }
    @media (max-width: 320px) {
      margin-bottom: 20px;
    }
  }
  & .popup__success {
    flex-direction: column;
    justify-content: center;
    top: -15%;
    bottom: -1%;
    &.active {
      top: 0;
    }
    & p {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
}
.carousel {
  &_review {
    margin-bottom: 100px;
    @media (max-width: 1280px) {
      margin-bottom: 65px;
    }
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }
  &__content {
    @media (max-width: 768px) {
     order: 2;
    }
  }
  &__img {
    @media (max-width: 768px) {
      order: 1;
    }
  }
  &__item {
      @media (max-width: 1024px) {
        padding: 0 100px;
      }
      @media (max-width: 680px) {
        padding: 0 30px;
      }
  }
}

.carouselCtrl {
  color: #86878D;
  position: absolute;
  left: calc(50% - 50px);
  top: -65px;
  user-select: none;
  @media (max-width: 768px) {
    top: -33px;
    right: 100px;
    left: unset;
  }
  @media (max-width: 680px) {
    right: 30px;
  }
  &__prev {
    margin-right: 20px;
    cursor: pointer;
  }
  &__next {
    margin-left: 20px;
    cursor: pointer;
    &_animated {
      @media (max-width: 768px) {
        display: inline-block;
        animation: scalingUpDown 1s alternate infinite ease-in;
      }
    }

  }

  &_review {
    @media (max-width: 680px) {
      right: unset;
      top: unset;
      left: calc(50% - 50px);
      bottom: -35px
    }
  }
}

.carouselNav {
  display: flex;
  justify-content: center;
  @media (max-width: 680px) {
    display: none;
  }
  &__item {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 2px solid transparent;
    margin: 0 40px;
    cursor: pointer;
    flex-shrink: 0;
    @media (max-width: 768px) {
      width: 55px;
      height: 55px;
      margin: 0 25px;
    }
    img {
      width: 100%;
    }
    &_active {
      border: 2px solid #0bc487;
    }
  }
}

.review {
  display: flex;
  justify-content: center;
  @media (max-width: 680px) {
    flex-direction: column;
  }
  &__img {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    margin-right: 85px;
    @media (max-width: 1280px) {
      width: 200px;
      height: 200px;
    }
    @media (max-width: 680px) {
      position: absolute;
      top: 0;
      margin-right: 0;
      width: 72px;
      height: 72px;
    }
    img {
      width: 100%;
    }
  }
  &__txt {
    padding-top: 15px;
    font-size: 18px;
    max-width: 500px;
    @media (max-width: 680px) {
      margin-bottom: 0;
    }
  }
  &__title {
    color: #0bc487;
    line-height: 1;
    margin-bottom: 8px;
    @media (max-width: 680px) {
      margin-left: 85px;
    }
  }
  &__subtitle {
    color: #86878D;
    line-height: 1;
    @media (max-width: 680px) {
      margin-left: 85px;
      margin-bottom: 50px;
    }
  }
}

.success {
  position: absolute;
  opacity: 0;
  transition: opacity .5s;
  &__message {
    font-size: 18px;
  }
}

.blank-page {
  padding: 30px;
}

.table-contract {
  margin-top: 15px;
}