.button {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 19px;
    background: #0bc487;
    border: 1px solid #0bc487;
    outline: none;
    font-family: 'Lato-Semibold', sans-serif;
    font-size: 14px;
    color: #fff;
    border-radius: 40px;
    cursor: pointer;
    transition: all 300ms ease;
    text-decoration: none;
    @media (max-width: 1280px) {
        font-size: 13px;
    }
    &:hover {
        box-shadow: 0 12px 27px 0 rgba(11, 196, 135, 0.2);
    }
    &:active {
        border-color: #0bc487;
        border-style: solid;
        transform: perspective(1px) scale(0.98);
    }
    &_transparent {
        background-color: transparent;
        color: #0BC487;
        &:hover {
            background-color: #0BC487;
            color: #fff;
        }
    }
    &_animated {
        animation: scaling .8s alternate infinite ease-in;
    }
    &_common {
        max-width: 224px;
        min-width: 224px;
        height: 50px;
        @media (max-width: 1280px) {
            height: 45px;
        }
    }
}