.dropdown {
  position: relative;
  color: #161923;
  font-size: 24px;
  font-weight: 700;
  border-bottom: 3px solid #161923;
  cursor: pointer;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 3px);
    right: 0;
    width: 12px;
    height: 7px;
    background-image: url(../icons/arrow_down.png);
    background-size: contain;
  }
  &__wrapper {
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 32px;
    background-color: #fff;
    margin: 0;
    list-style-type: none;
    padding: 0;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: all 0.3s;
    &_active {
      opacity: 1;
      height: auto;
    }
  }
  &__current {
    padding-left: 15px;
    @media (max-width: 414px) {
      padding-left: 0;
    }
  }
  &__item {
    padding: 15px;
    color: #9b9ea3;
    font-size: 16px;
    transition: all 0.3s;
    &:hover {
      background-color: #fcfcfc;
    }
  }
}
