.popup {
    display: none;
    position: relative;
    padding: 25px;
    margin: 1.75rem auto;
    background-color: #fff;
    pointer-events: none;
    z-index: 1011;
    @media (max-width: 680px) {
        width: 95%;
    }
    @media (max-width: 320px) {
        padding: 15px;
    }
    &_active {
        pointer-events: auto;
        display: inline-block;
    }
    &__close {
        font-size: 25px;
        font-family: 'Lato-Bold', sans-serif;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 5px;
        @media (max-width: 680px) {
            right: 15px;
            top: 10px;
        }
        @media (max-width: 320px) {
            right: 10px;
            top: 5px;
        }
    }
    &__title {
        font-size: 26px;
        margin-bottom: 50px;
        padding-left: 15px;
        text-align: left;
        @media (max-width: 1280px) {
            margin-bottom: 30px;
        }
        @media (max-width: 320px) {
            font-size: 22px;
        }
    }
    &__input-wrapper {
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        .form__input-placeholder {
            top: 5px;
            left: 15px;
            @media (max-width: 320px) {
                font-size: 14px;
                top: 10px;
            }
            &_filled {
                top: -20px;
                left: 15px;
                @media (max-width: 980px) {
                    top: -15px;
                }
                @media (max-width: 320px) {
                    top: -10px;
                    font-size: 12px;
                }
                &+.popup__input:focus {
                    border-bottom: 1px solid #0bc487;
                }
                &+.popup__input {
                    border-bottom: 1px solid #161923;
                }
            }
        }
    }
    &__input {
        width: 100%;
        border-bottom: 2px solid #f5f7f9;
        @media (max-width: 320px) {
            font-size: 14px;
        }
    }
    &__row {
        margin-bottom: 40px;
    }
    &__form {
        position: relative;
    }
    &__success {
        position: absolute;
        left: 0;
        top: -17%;
        bottom: -10%;
        right: 0;
        text-align: center;
        background: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        padding: 5%;
        opacity: 0;
        height: 0;
        transition: opacity .3s;
    }
    &__button {
        max-width: 224px;
        height: 50px;
        @media (max-width: 1280px) {
            height: 45px;
        }
    }
}

.popup-overlay {
    position: fixed;
    z-index: 1010;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    text-align: center;
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity .3s ease-in-out;
    background-color: rgba(244, 247, 249, .9);
    &_active {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        visibility: visible;
        opacity: 1;
    }
}
