.demo-card {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    overflow: hidden;
    &__item {
        top: 0;
        bottom: 0;
        position: absolute;
        width: 100%;
        display: flex;
        opacity: 0;
        transition: all .3s;
        &_active {
          opacity: 1;
          z-index: 1;
        }
        &_1 {
          background-image: url(../images/lp__img_demo-card-1.png);
          justify-content: center;
          align-items: flex-end;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 88%;
        }
        &_2 {
            background-image: url(../images/lp__img_demo-card-2.png);
            justify-content: center;
            align-items: center;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 88%;
        }
    }
    &__form {
        width: 80%;
        position: relative;
    }
    &__input-wrapper {
        width: 100%;
    }
    &__input-placeholder {
        left: 0 !important;
        top: 6px !important;
        @media (max-width: 1024px) {
            font-size: 14px;
            top: 9px !important;
        }
        &.form__input-placeholder_filled {
            top: -20px !important;
            @media (max-width: 1024px) {
                top: -15px !important;
                font-size: 12px;
            }
            &+.demo-card__input:focus {
                border-bottom: 1px solid #0bc487;
            }
            &+.demo-card__input {
                border-bottom: 1px solid #0bc487;
            }
        }
    }
    &__input {
        color: #fff;
        margin-bottom: 18%;
        @media (max-width: 1024px) {
            font-size: 14px;
        }
        &.-invalid {
            margin-bottom: 18%;
        }
    }
    &__success {
        position: absolute;
        left: 0;
        top: -30%;
        bottom: -10%;
        right: 0;
        text-align: center;
        background: rgb(25,25,29);
        display: flex;
        align-items: center;
        padding: 5%;
        opacity: 0;
        height: 0;
        transition: all .3s;
    }
    &__back {
        position: absolute;
        top: 8%;
        left: 8%;
        width: 10%;
        height: 5%;
        cursor: pointer;
        border: transparent;
        background: transparent;
    }
}
.demo-example {
    display: flex;
    position: absolute;
    top: calc(100% - 69%);
    right: 0;
    width: 38%;
    overflow-x: hidden;
    z-index: 2;
    @media (max-width: 768px) {
        position: static;
        width: 100%;
        overflow-x: scroll;
        margin-bottom: 45px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media (max-width: 680px) {
        padding-left: 30px;
    }
    &__pic {
        width: 36%;
        @media (max-width: 680px) {
            width: 80%;
            flex-shrink: 0;
        }
    }
    &__pic:not(:last-child) {
        margin-right: 4%;
    }
    &__pic:last-child {
        .demo-example__item {
            @media (max-width: 680px) {
                margin-right: 30px;
            }
        }
    }
    &__item {
        width: 100%;
    }
}
