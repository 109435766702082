.header-main {
  height: 80px;
  background-color: #0F1829;
  position: relative;
  z-index: 2;
  @media (max-width: 1280px) {
    height: 60px;
  }
  @media (max-width: 680px) {
    height: 85px;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
      padding: 0 30px !important;
    }
  }

  &__logo {
    width: 105px;
    height: 60px;
    @media (max-width: 1024px) {
      width: 72px;
      height: 37px;
    }
    @media (max-width: 680px) {
      width: 95px;
      height: 49px;
    }
  }

  &__bar {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__menu {
    margin-right: 73px;
    @media (max-width: 1024px) {
      margin-right: 48px;
    }
  }

  &__btn {
    max-width: 208px;
    min-width: 208px;
    height: 40px;
    margin-right: 33px;
    @media (max-width: 1280px) {
      height: 35px;
    }
    @media (max-width: 680px) {
      display: none !important;
    }
  }

  &__link {
    margin-right: 10px;
    @media (max-width: 680px) {
      display: none;
    }
  }
  &__phone {
    position: relative;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    margin-right: 33px;
    top: 1px;
    @media (max-width: 680px) {
      margin-right: 0;
      top: 2px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      top: 0;
    }
    @media (max-width: 320px) {
      top: -1px;
    }
  }
}

.header-menu {
  height: 100%;
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 2px solid transparent;
    color: #fff;
    text-decoration: none;
    transition: opacity .4s;
    white-space: nowrap;

    &--active {
      color: #0BC487;
      border-color: #0BC487;
    }

    &:not(:last-child) {
      margin-right: 85px;
      @media (max-width: 1024px) {
        margin-right: 45px;
      }
      @media (max-width: 768px) {
        margin-right: 40px;
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.burger {
  width: 19px;
  height: 14px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 680px) {
    display: flex;
  }

  &__line {
    width: 19px;
    height: 2px;
    position: relative;
    background: #F9FAFB;
    transition: all .3s ease-in-out;

    &:before {
      content: '';
      width: 19px;
      height: 2px;
      display: block;
      position: absolute;
      top: 7px;
      background: #F9FAFB;
      transition: all .3s ease-in-out;
    }

    &:after {
      content: '';
      width: 19px;
      height: 2px;
      display: block;
      position: absolute;
      bottom: 7px;
      background: #F9FAFB;
      transition: all .3s ease-in-out;
    }
  }

  &--active {

    .burger__line {
      background: rgba(255,255,255,.0);

      &:before {
        top: 0;
        transform: rotate(45deg);
      }

      &:after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }
}

.menu-mobile {
  display: none;
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 3;
  transform: translate(100%, 0);
  transition: transform .3s ease;
  @media (max-width: 680px) {
    display: block;
  }
  &.active {
    transform: translate(0, 0);
  }
  &__list {
    list-style-type: none;
    padding: 15px 0;
    transform: translate(35%, 0);
  }
  &__item {
    padding: 15px 0;
  }
  &__link {
    color: #161923;
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Lato-Bold', sans-serif;
  }
}